<template>
  <div>
    <div class="mb-4">
      <div class="row">
        <div class="col-12">
          <div class="title__sensor text-center">
            <div>Sensor de {{ item.title }}</div>
          </div>
          <div class="card card__sensor">
            <div class="card__title text-center mb-2">
              Temperatura - {{ item.area }} - {{ item.pcbname }}
            </div>
            <div class="row mb-3">
              <div class="col-lg-6 col-md-6 col-12">
                <alarm-table :data="item.top5MinTemp" @dateTimeSelected="dateTimeSelected" />
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <alarm-table :data="item.top5MaxTemp" @dateTimeSelected="dateTimeSelected" />
              </div>
            </div>
            <div class="card__title text-center mb-2">
              Humedad - {{ item.area }} - {{ item.pcbname }}
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <alarm-table :data="item.top5MinHum" @dateTimeSelected="dateTimeSelected" />
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <alarm-table :data="item.top5MaxHum" @dateTimeSelected="dateTimeSelected" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="title2 mb-2">{{ item.title }}</div>
      <div id="chart-4">
        <apexchart
          type="line"
          height="400"
          :options="item.options"
          :series="item.series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import AlarmTable from './AlarmTable.vue'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  components: {
    AlarmTable,
  },
  methods: {
    dateTimeSelected(datetime1, datetime2) {
      this.$emit('dateTimeSelected', datetime1, datetime2)
    },
  },
};
</script>

<style></style>
