<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr class="text-center">
          <th class="title3">Límites de alarma</th>
          <th class="title3">Alarmas</th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr>
          <td class="title2">{{ data.text }} {{ data.value }}°C</td>
          <td>
            <template v-if="data.top5.length > 0">
              <div
                v-for="(top, index) in data.top5"
                :key="index"
                @click="dateTimeSelected(top.timestamp, top.timestamp)"
                class="eventClickItem"
              >
                <span class="item2">{{ top.value + top.unit }}</span
                ><span class="item2">{{ top.timestamp }}</span>
              </div>
            </template>
            <template v-else>
              <div>
                <span class="item2">Sin alarmas</span>
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  methods: {
    dateTimeSelected(datetime1, datetime2) {
      this.$emit('dateTimeSelected', datetime1, datetime2)
    },
  },
};
</script>

<style></style>
