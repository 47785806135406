<template>
  <div>
    <Header />
    <div class="container">
      <div class="card p-3 card__admin">
        <div class="row">
          <div class="col-12">
            <a class="icono__home" @click="home()">
              <i class="fas fa-home"></i>
            </a>
          </div>
          <div class="title__section mb-3 text-center">
            Reporte del <span class="title__">{{ dateTimeInit }}</span> al
            <span class="title__">{{ datetimeFin }}</span>
          </div>
          <div class="col-12">
            <div class="title__section text-left">
              Empresa: <span class="title__">{{ enterprise }}</span>
            </div>
          </div>
          <div class="col-12 mb-2">
            <div class="title__section text-left">
              Area: <span class="title__">{{ area }}</span>
            </div>
          </div>
          <template v-if="spinner">
            <div class="container__home">
              <div class="img__spinner" v-if="spinner">
                <img src="@/assets/img/LogoHelpSmart-01.png" alt="helpSmart" />
              </div>
            </div>
          </template>
          <template v-else>
            <template v-if="hits.length > 0">
              <div class="col-12 mt-2">
                <div class="title__section text-center">Sensores</div>
                <div v-if="batteries.length > 0">
                  <div
                    class="batterry text-center mb-2"
                    v-for="(item, index) in batteries"
                    :key="index"
                  >
                    Batería: <i class="fas fa-battery-three-quarters"></i>
                    {{ item.value + " " + item.unit }}
                  </div>
                </div>
              </div>
              <div v-for="(item, index) in graphicsTemperatures" :key="index">
                <graphic-temperature :item="item" @dateTimeSelected="dateTimeSelected" />
              </div>
              <div v-for="item in graphicsIndicators" :key="item.name">
                <graphic-indicator :item="item" />
              </div>
              <div v-for="item in graphicsTempeAndHumidities" :key="item.name">
                <graphic-temperature-and-humidity :item="item" @dateTimeSelected="dateTimeSelected" />
              </div>
            </template>
            <template v-else>
              <div class="container__home">
                <div class="">
                  <div class="title2 text-center">
                    No hay sensores para mostrar en este rango de tiempo.
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("es");
let es = require("apexcharts/dist/locales/es.json");
import Header from "@/components/header/Header";
import GraphicTemperature from "@/components/GraphicTemperature";
import GraphicIndicator from "@/components/GraphicIndicator";
import GraphicTemperatureAndHumidity from "@/components/GraphicTemperatureAndHumidity";

export default {
  name: "ReportGraphics",
  inject: ["deviceRepository"],
  components: {
    Header,
    GraphicTemperature,
    GraphicIndicator,
    GraphicTemperatureAndHumidity,
  },

  data() {
    const me = this;
    return {
      mac: me.$route.params.mac,
      dateTimeInit: me.$route.params.dateTimeInit,
      datetimeFin: me.$route.params.datetimeFin,
      area: me.$route.params.area,
      enterprise: me.$route.params.enterprise,
      interval: me.$route.params.interval,
      chartOptionsTemp: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
            pan: false,
            export: {
              csv: {
                filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
                },
              },
              svg: {
                filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
              },
              png: {
                filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
              },
            },
          },
          locales: [es],
          defaultLocale: "es",
          animations: {
            speed: 200,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
          title: {
            text: "Tiempo",
          },
          labels: {
            formatter: function (value, timestamp) {
              return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
            },
            offsetX: 20,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          title: {
            text: "Temperatura",
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: "#F32F2F",
              fillColor: "#F32F2F",
              label: {
                borderColor: "#fff",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#F32F2F",
                },
                text: "Max",
              },
            },
            {
              y: 0,
              borderColor: "#F32F2F",
              fillColor: "#F32F2F",
              label: {
                borderColor: "#fff",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#F32F2F",
                },
                text: "Min",
              },
            },
          ],
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: undefined,
          },
          x: {
            show: true,
            format: "dd MMM - HH:mm",
            formatter: undefined,
          },
        },
      },
      chartOptionsInd: {
        chart: {
          height: 250,
          type: "line",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
          locales: [es],
          defaultLocale: "es",
          animations: {
            speed: 200,
          },
          events: {
            markerClick: (event, chartContext, { dataPointIndex }) => {
              let timestamp1 =
                chartContext.w.config.xaxis.categories[dataPointIndex];
              let timestamp2 =
                chartContext.w.config.xaxis.categories[dataPointIndex];

              me.dateTimeSelected(timestamp1, timestamp2);
            },
          },
        },
        colors: ["#FF4560"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
          title: {
            text: "Tiempo",
          },
          labels: {
            formatter: function (value, timestamp) {
              return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
            },
            offsetX: 20,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
          min: -1,
          max: 1,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: undefined,
          },
          x: {
            show: true,
            format: "dd MMM - HH:mm",
            formatter: undefined,
          },
        },
        markers: {
          size: [4, 7],
          colors: ["#FF4560"],
          strokeColors: "#fff",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 2,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
      },
      chartOptionsEnerg: {
        chart: {
          height: 150,
          type: "line",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
          animations: {
            speed: 200,
          },
        },
        colors: ["#FF4560"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
          title: {
            text: "Tiempo",
          },
          labels: {
            formatter: function (value, timestamp) {
              return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
            },
            offsetX: 20,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
          min: -1,
          max: 1,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: undefined,
          },
          x: {
            show: true,
            format: "dd MMM - HH:mm",
            formatter: undefined,
          },
        },
      },
      chartOptionsTempeAndHumidities: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
            pan: false,
            export: {
              csv: {
                filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
                },
              },
              svg: {
                filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
              },
              png: {
                filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
              },
            },
          },
          locales: [es],
          defaultLocale: "es",
          animations: {
            speed: 200,
          },
        },
        colors: ["#0d6efd", "#FF4560"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "datetime",
          categories: [],
          title: {
            text: "Tiempo",
          },
          labels: {
            formatter: function (value, timestamp) {
              return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
            },
            offsetX: 20,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          title: {
            text: "",
          },
        },
        annotations: {
          yaxis: [
            {
              y: 0,
              borderColor: "#0d6efd",
              fillColor: "#0d6efd",
              label: {
                borderColor: "#fff",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#0d6efd",
                },
                text: "Max",
              },
            },
            {
              y: 0,
              borderColor: "#0d6efd",
              fillColor: "#0d6efd",
              label: {
                borderColor: "#fff",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#0d6efd",
                },
                text: "Min",
              },
            },
            {
              y: 0,
              borderColor: "#FF4560",
              fillColor: "#FF4560",
              label: {
                borderColor: "#fff",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#FF4560",
                },
                text: "Max",
              },
            },
            {
              y: 0,
              borderColor: "#FF4560",
              fillColor: "#FF4560",
              label: {
                borderColor: "#fff",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#FF4560",
                },
                text: "Min",
              },
            },
          ],
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: undefined,
          },
          x: {
            show: true,
            format: "dd MMM - HH:mm",
            formatter: undefined,
          },
        },
      },
      graphicsTemperatures: [],
      graphicsIndicators: [],
      graphicsTempeAndHumidities: [],
      spinner: false,
      pcbname: "",
      batteries: [],
      hits: [],
    };
  },

  methods: {
    async search() {
      const me = this;
      me.spinner = true;

      me.graphicsTemperatures = [];
      me.graphicsIndicators = [];
      me.graphicsTempeAndHumidities = [];
      me.batteries = [];
      me.hits = [];

      try {
        const res = await me.deviceRepository.getAll(
          me.mac,
          me.dateTimeInit,
          me.datetimeFin
        );

        /* const { hits } = res.data.hits; */
        me.hits = res.data;
        console.log(me.hits)
        let sensors = [];

        if (me.hits.length > 0) me.pcbname = me.hits[0].pcbname;

        me.hits.map((hit) => {
          hit.sensor.forEach((x) => {
            let sensor = sensors.find(
              (se) => se.type === x.type && se.number === x.number
            );

            if (sensor) {
              sensors.map((resp) => {
                if (
                  resp.type === sensor.type &&
                  resp.number === sensor.number
                ) {
                  let value1 = {
                    Lmax: x.values[0].Lmax,
                    Lmin: x.values[0].Lmin,
                    type: x.values[0].type,
                    unit: x.values[0].unit,
                    value: x.values[0].value,
                    timestamp: hit.timestamp,
                  };

                  if (sensor.type == 1) {
                    let value2 = {
                      Lmax: x.values[1].Lmax,
                      Lmin: x.values[1].Lmin,
                      type: x.values[1].type,
                      unit: x.values[1].unit,
                      value: x.values[1].value,
                      timestamp: hit.timestamp,
                    };
                    resp.values.push(value1, value2);
                  } else {
                    resp.values.push(value1);
                  }
                }
              });
            } else {
              let value = {
                Lmax: "",
                Lmin: "",
                type: "",
                unit: "",
                value: "",
                timestamp: "",
              };

              x.values.map((el) => {
                (value.Lmax = el.Lmax),
                  (value.Lmin = el.Lmin),
                  (value.type = el.type),
                  (value.unit = el.unit),
                  (value.value = el.value),
                  (value.timestamp = hit.timestamp);
              });
              sensors.push({
                name: x.name,
                number: x.number,
                type: x.type,
                values: [value],
              });
            }
          });
        });
        console.log(sensors);

        sensors.map((sensor) => {
          if (sensor.type == 13 && sensor.number == 1) {
            me.fillArrayGraphicTemp(sensor);
          } else if (sensor.type == 13 && sensor.number == 2) {
            me.fillArrayGraphicTemp(sensor);
          } else if (sensor.type == 10 && sensor.number == 1) {
            let indica = {
              name: "",
              data: [],
            };
            let title = "Indicador de energía";

            sensor.values.map((indi) => {
              if (indi.value !== 1) {
                indica.name = "Apagado";
                indica.data.push(indi.value);
                me.chartOptionsInd.xaxis.categories.push(indi.timestamp);
              }
            });

            me.chartOptionsInd.colors = ["#FF4560"];
            me.chartOptionsInd.markers.colors = ["#FF4560"];

            const text = "Apagado";
            const classCircle = "off__circle";

            let data = {
              title,
              series: [indica],
              options: me.chartOptionsInd,
              text,
              classCircle
            };

            me.graphicsIndicators.push(data);
          } else if (sensor.type == 10 && sensor.number == 2) {
            
            let indica = {
              name: "",
              data: [],
            };
            let title = "Indicador de planta eléctrica";

            sensor.values.map((indi) => {
              if (indi.value !== 0) {
                indica.name = "Encedida";
                indica.data.push(indi.value);
                me.chartOptionsInd.xaxis.categories.push(indi.timestamp);
              }
            });

            me.chartOptionsInd.colors = ["#6ab23c"];
            me.chartOptionsInd.markers.colors = ["#6ab23c"];

            const text = "Encedida";
            const classCircle = "on__circle";

            let data = {
              title,
              series: [indica],
              options: me.chartOptionsInd,
              text,
              classCircle
            };

            me.graphicsIndicators.push(data);
          } else if (sensor.type == 10 && sensor.number == 3) {
            let indica = {
              name: "",
              data: [],
            };
            let title = "Indicador de apertura de puerta";

            sensor.values.map((indi) => {
              if (indi.value !== 1) {
                indica.name = "Apagado";
                indica.data.push(indi.value);
                me.chartOptionsInd.xaxis.categories.push(indi.timestamp);
              }
            });

            me.chartOptionsInd.colors = ["#FF4560"];
            me.chartOptionsInd.markers.colors = ["#FF4560"];
            const text = "Apagado";
            const classCircle = "off__circle";

            let data = {
              title,
              series: [indica],
              options: me.chartOptionsInd,
              text,
              classCircle
            };

            me.graphicsIndicators.push(data);
          } else if (sensor.type == 1 && sensor.number == 1) {
            me.fillArrayGraphicTempHum(sensor)
          } else if (sensor.type == 1 && sensor.number == 2) {
            me.fillArrayGraphicTempHum(sensor)
          } else if (sensor.type == 11 && sensor.number == 1) {
            let { values } = sensor;
            let battery = values[values.length - 1];
            me.batteries.push(battery);
          } else if (sensor.type == 100 && sensor.number == 1) {
            let { values } = sensor;
            let battery = values[values.length - 1];
            me.batteries.push(battery);
          }
        });
        console.log(me.graphicsIndicators)
      } catch (error) {
        console.log(error);
        me.spinner = false;
      } finally {
        me.spinner = false;
      }
    },
    async search2() {
      const me = this
      me.find()
    },
    async find() {
      const me = this
      try {
        const res = await me.deviceRepository.find(
          me.mac,
          me.dateTimeInit,
          me.datetimeFin
        )
        const { data } = res

        data[0].sensor.forEach(el => {
          console.log(el)
          if (el.type == 1) {
            me.sensorByInterval('temperature-humedity')
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    dateTimeSelected(datetime1, datetime2) {
      const me = this;

      let timestamp1 = moment(datetime1);
      let timestamp2 = moment(datetime2);

      const last2Minutes = timestamp1
        .subtract(10, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      const later2Minutes = timestamp2
        .add(20, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");

      me.$router.push({
        name: "report-graphics",
        params: {
          mac: me.mac,
          area: me.area,
          enterprise: me.enterprise,
          dateTimeInit: last2Minutes,
          datetimeFin: later2Minutes,
        },
      });
    },
    home() {
      const me = this;
      const last1Hour = (moment().subtract(1, "hour"),
      moment().subtract(1, "hour")).format("YYYY-MM-DD HH:mm:ss");
      const today = (moment(), moment()).format("YYYY-MM-DD HH:mm:ss");

      me.$router.push({
        name: "report-graphics",
        params: {
          mac: me.mac,
          area: me.area,
          interval: 'null',
          enterprise: me.enterprise,
          dateTimeInit: last1Hour,
          datetimeFin: today,
        },
      });
    },
    fillArrayGraphicTemp(sensor) {
      const me = this;
      let temp = {
        name: "",
        data: [],
      };

      let title = "Temperatura" + " " + "N°" + sensor.number;
      let tempMax = [];
      let tempMin = [];
      let sensorT = [];

      sensor.values.map((tem) => {
        temp.name = tem.unit;
        temp.data.push(tem.value);
        me.chartOptionsTemp.xaxis.categories.push(tem.timestamp);
        sensorT.push(tem);
      });

      let maxTemp = sensorT[0].Lmax;
      let minTemp = sensorT[0].Lmin;

      me.chartOptionsTemp.annotations.yaxis[0].y = maxTemp;
      me.chartOptionsTemp.annotations.yaxis[1].y = minTemp;

      sensorT.map((el) => {
        if (parseFloat(el.value) > maxTemp) {
          tempMax.push(el);
        }

        if (parseFloat(el.value) < minTemp) {
          tempMin.push(el);
        }
      });

      let tempMaxOrder = tempMax.sort(function (a, b) {
        return a - b;
      });

      let tempMinOrder = tempMin.sort(function (a, b) {
        return a - b;
      });

      let topMax = tempMaxOrder.slice(tempMaxOrder.length - 5);
      let topMin = tempMinOrder.slice(tempMinOrder.length - 5);

      let data = {
        title,
        top5Min: {
          text: 'Min',
          value: minTemp,
          top5: topMin,
        },
        top5Max: {
          text: 'Max',
          value: maxTemp,
          top5: topMax,
        },
        series: [temp],
        options: me.chartOptionsTemp,
        area: me.area,
        pcbname: me.pcbname,
      };

      me.graphicsTemperatures.push(data);
    },
    fillArrayGraphicTempHum(sensor) {
      const me = this;
      let temp = {
        name: "",
        data: [],
      };

      let humed = {
        name: "",
        data: [],
      };

      let series = [];
      let tempMax = [];
      let tempMin = [];
      let sensorT = [];
      let humdMax = [];
      let humdMin = [];
      let sensorHum = [];

      let title = "Temperatura y Humedad" + " " + "N°" + sensor.number;

      sensor.values.map((sens) => {
        if (sens.type == "TEMPERATURA") {
          temp.name = "Temperatura" + " " + sens.unit;
          temp.data.push(sens.value);
          me.chartOptionsTempeAndHumidities.xaxis.categories.push(
            sens.timestamp
          );
          sensorT.push(sens);
        } else {
          humed.name = "Humedad" + " " + sens.unit;
          humed.data.push(sens.value);
          sensorHum.push(sens);
        }
      });

      let maxTemp = sensorT[0].Lmax;
      let minTemp = sensorT[0].Lmin;
      let maxHum = sensorHum[0].Lmax;
      let minHum = sensorHum[0].Lmin;

      me.chartOptionsTempeAndHumidities.annotations.yaxis[0].y = maxTemp;
      me.chartOptionsTempeAndHumidities.annotations.yaxis[1].y = minTemp;
      me.chartOptionsTempeAndHumidities.annotations.yaxis[2].y = maxHum;
      me.chartOptionsTempeAndHumidities.annotations.yaxis[3].y = minHum;

      sensorT.map((el) => {
        if (parseFloat(el.value) > maxTemp) {
          tempMax.push(el);
        }

        if (parseFloat(el.value) < minTemp) {
          tempMin.push(el);
        }
      });

      let tempMaxOrder = tempMax.sort(function (a, b) {
        return a - b;
      });

      let tempMinOrder = tempMin.sort(function (a, b) {
        return a - b;
      });

      let topMaxTemp = tempMaxOrder.slice(tempMaxOrder.length - 5);
      let topMinTemp = tempMinOrder.slice(tempMinOrder.length - 5);

      sensorHum.map((el) => {
        if (parseFloat(el.value) > maxHum) {
          humdMax.push(el);
        }

        if (parseFloat(el.value) < minHum) {
          humdMin.push(el);
        }
      });

      let humMaxOrder = humdMax.sort(function (a, b) {
        return a - b;
      });

      let humMinOrder = humdMin.sort(function (a, b) {
        return a - b;
      });

      let topMaxHum = humMaxOrder.slice(humMaxOrder.length - 5);
      let topMinHum = humMinOrder.slice(humMinOrder.length - 5);

      series.push(temp, humed);

      let data = {
        title,
        top5MinTemp: {
          text: 'Min',
          value: minTemp,
          top5: topMinTemp,
        },
        top5MaxTemp: {
          text: 'Max',
          value: maxTemp,
          top5: topMaxTemp,
        },
        top5MinHum: {
          text: 'Min',
          value: minHum,
          top5: topMinHum,
        },
        top5MaxHum: {
          text: 'Max',
          value: maxHum,
          top5: topMaxHum,
        },
        series: series,
        options: me.chartOptionsTempeAndHumidities,
        area: me.area,
        pcbname: me.pcbname,
      };

      me.graphicsTempeAndHumidities.push(data);
    },
    async sensorByInterval(type) {
      const me = this
      try {
        const res = await me.deviceRepository.getDevicesByInteval(
          me.mac,
          type,
          me.dateTimeInit,
          me.datetimeFin
        )
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
  },

  beforeRouteUpdate(to, from, next) {
    const me = this;
    me.dateTimeInit = to.params.dateTimeInit;
    me.datetimeFin = to.params.datetimeFin;
    me.interval = to.params.interval;

    me.chartOptionsTemp = {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
          pan: false,
          export: {
            csv: {
              filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            svg: {
              filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
            },
            png: {
              filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
            },
          },
        },
        locales: [es],
        defaultLocale: "es",
        animations: {
          speed: 200,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        title: {
          text: "Tiempo",
        },
        labels: {
          formatter: function (value, timestamp) {
            return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
          },
          offsetX: 20,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: "Temperatura",
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "#F32F2F",
            fillColor: "#F32F2F",
            label: {
              borderColor: "#fff",
              style: {
                fontSize: "12px",
                color: "#fff",
                background: "#F32F2F",
              },
              text: "Max",
            },
          },
          {
            y: 0,
            borderColor: "#F32F2F",
            fillColor: "#F32F2F",
            label: {
              borderColor: "#fff",
              style: {
                fontSize: "12px",
                color: "#fff",
                background: "#F32F2F",
              },
              text: "Min",
            },
          },
        ],
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: undefined,
        },
        x: {
          show: true,
          format: "dd MMM - HH:mm",
          formatter: undefined,
        },
      },
    };
    me.chartOptionsInd = {
      chart: {
        height: 250,
        type: "line",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
        locales: [es],
        defaultLocale: "es",
        animations: {
          speed: 200,
        },
        events: {
          markerClick: (event, chartContext, { dataPointIndex }) => {
            let timestamp1 = moment(
              chartContext.w.config.xaxis.categories[dataPointIndex]
            );
            let timestamp2 = moment(
              chartContext.w.config.xaxis.categories[dataPointIndex]
            );

            me.dateTimeSelected(timestamp1, timestamp2);
          },
        },
      },
      colors: ["#FF4560"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        title: {
          text: "Tiempo",
        },
        labels: {
          formatter: function (value, timestamp) {
            return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
          },
          offsetX: 20,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
        min: -1,
        max: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: undefined,
        },
        x: {
          show: true,
          format: "dd MMM - HH:mm",
          formatter: undefined,
        },
      },
      markers: {
        size: [4, 7],
        colors: ["#FF4560"],
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        shape: "circle",
        radius: 2,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
    };
    me.chartOptionsEnerg = {
      chart: {
        height: 150,
        type: "line",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
        },
        animations: {
          speed: 200,
        },
      },
      colors: ["#FF4560"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        title: {
          text: "Tiempo",
        },
        labels: {
          formatter: function (value, timestamp) {
            return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
          },
          offsetX: 20,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
        min: -1,
        max: 1,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: undefined,
        },
        x: {
          show: true,
          format: "dd MMM - HH:mm",
          formatter: undefined,
        },
      },
      legend: {
        floating: true,
      },
    };
    (me.chartOptionsTempeAndHumidities = {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
          pan: false,
          export: {
            csv: {
              filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
              },
            },
            svg: {
              filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
            },
            png: {
              filename: "report-" + moment().format("YYYY-MM-DD HH:mm:ss"),
            },
          },
        },
        locales: [es],
        defaultLocale: "es",
        animations: {
          speed: 200,
        },
      },
      colors: ["#0d6efd", "#FF4560"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: [],
        title: {
          text: "Tiempo",
        },
        labels: {
          formatter: function (value, timestamp) {
            return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
          },
          offsetX: 20,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "#0d6efd",
            fillColor: "#0d6efd",
            label: {
              borderColor: "#fff",
              style: {
                fontSize: "12px",
                color: "#fff",
                background: "#0d6efd",
              },
              text: "Max",
            },
          },
          {
            y: 0,
            borderColor: "#0d6efd",
            fillColor: "#0d6efd",
            label: {
              borderColor: "#fff",
              style: {
                fontSize: "12px",
                color: "#fff",
                background: "#0d6efd",
              },
              text: "Min",
            },
          },
          {
            y: 0,
            borderColor: "#FF4560",
            fillColor: "#FF4560",
            label: {
              borderColor: "#fff",
              style: {
                fontSize: "12px",
                color: "#fff",
                background: "#FF4560",
              },
              text: "Max",
            },
          },
          {
            y: 0,
            borderColor: "#FF4560",
            fillColor: "#FF4560",
            label: {
              borderColor: "#fff",
              style: {
                fontSize: "12px",
                color: "#fff",
                background: "#FF4560",
              },
              text: "Min",
            },
          },
        ],
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: undefined,
        },
        x: {
          show: true,
          format: "dd MMM - HH:mm",
          formatter: undefined,
        },
      },
    });
    
    me.interval ? me.search2() : me.search()
    next();
  },

  created() {
    const me = this;
    me.interval !== 'null' ? me.search2() : me.search()
  },
};
</script>

<style></style>
