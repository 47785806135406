<template>
  <div>
    <div class="col-12 mb-4">
      <div class="title2 mb-2">{{ item.title }}</div>
      <div v-if="item.series[0].data.length > 0">
        <div class="d-flex align-items-center">
          <div class="circle" :class="item.classCircle" />
          <div class="text__circle">{{ item.text }}</div>
        </div>
        <div id="chart-2">
          <apexchart
            type="line"
            height="250"
            :options="item.options"
            :series="item.series"
          ></apexchart>
        </div>
      </div>
      <div v-else>
        <div class="title2 text-center">
          No presenta cortes de energía en este rango de tiempo.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style></style>
